export const environment = {
    production: true,
    envName: 'dev',
    ROADMAP_UNDERWRITING_SECURITY_SOURCE_ID: '10200f1017NTLvDqGGF',
    ROADMAP_UNDERWRITING_SECURITY_TOKEN: 'qsD4gjRT0yubF4TlUo0YU6xcDsaR5SifO7j1GMNwl3YbsTl4Poo6dX0tsZ18yqZpHQurUtz3JLdH3Ntsk3FfgGO7dwKN6qqKlFfW8fWE7',
    SR_BASE_API_URL: 'https://crm-settlement-readiness.dev.fdrgcp.com',
    ROADMAP_SECURITY_SOURCE_ID: '10200f1017NTLvDqGGF',
    ROADMAP_SECURITY_TOKEN: 'qsD4gjRT0yubF4TlUo0YU6xcDsaR5SifO7j1GMNwl3YbsTl4Poo6dX0tsZ18yqZpHQurUtz3JLdH3Ntsk3FfgGO7dwKN6qqKlFfW8fWE7',
    AUTH_BASE_API_URL: 'https://crm-sif-api.dev.fdrgcp.com/v1/auth'
};
